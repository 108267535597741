import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div style={{maxWidth: '700px', margin: '0 auto', textAlign: 'center'}}>
      <h1>Sidan kunde inte hittas :(</h1>
      <p>Det verkar som att det har blivit något fel! Kanske var det en knasig länk,
      eller är det något som fattas. Om länken är korrekt, kontakta oss gärna på 
      info@kvarntakt.se så att vi kan fixa det! :)</p>
      <p>Tillbaka till <a href="/">startsidan.</a></p>
    </div>
  </Layout>
)

export default NotFoundPage
